<template>
  <div id="app">
    <!-- This is where the components corresponding to the routes will be rendered -->
    <router-view></router-view>
  </div>
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  name: 'App',
  setup() {
    const siteData = reactive({
      title: `Gorex Digital | Smart Fuel Solution and Fuel Payment Solution`,
      description: `Gorex.pk offers smart fuel solutions and digital payment solutions. Experience easy fuel payment solutions and secure payment services for seamless transactions.`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  }
}
</script>

<style>

</style>